#notations-table-modal{
    label {
        font-size: 90% !important;
        margin: .25rem 1rem .25rem 0;
    }
}

.input-pd-modal{
    width: 100px !important;
    border-radius: 15px;
    padding: 3px 5px 3px;
    height: 35px !important;
    outline: none;
}

.input-pd-modal:focus{
    border: solid 2px #0a5995 !important;
}

.select-pd-modal{
    width: 200px !important;
}

.modal-pd{
    max-width: 1000px !important;
}

#decimals-table-modal{
    label {
        font-size: 90% !important;
        margin: .25rem 1rem .25rem 0;
    }
}

#comodin-table-modal{
    label {
        font-size: 90% !important;
        margin: .25rem 1rem .25rem 0;
    }
}
